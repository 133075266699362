import { isSchemaUnion, frontendOnly, onlyNotCreate } from './chunk-DMAKCGVJ.js';
export { FrontendOnlyKind, NumberEnum, OnlyNotCreateKind, StringEnum, combineObjectUnion, frontendOnly, isSchemaFrontendOnly, isSchemaIntersect, isSchemaObject, isSchemaOnlyNotCreate, isSchemaOptional, isSchemaReadonly, isSchemaUnion, isSchemaVirtual, onlyNotCreate } from './chunk-DMAKCGVJ.js';
import { Type, Kind } from '@sinclair/typebox';
export * from '@sinclair/typebox';
export { Value } from '@sinclair/typebox/value';
export { TypeCompiler } from '@sinclair/typebox/compiler';

// src/extended-types/attach-service.ts
function attachToSchema(schema, options) {
  Object.assign(schema, options);
  return schema;
}
var HexColor = (options) => {
  return Type.String({
    ...options,
    pattern: "^#([A-Fa-f0-9]{8}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{4}|[A-Fa-f0-9]{3})$"
  });
};
var LiteralUnion = (values) => {
  return Type.Union(values.map((value) => Type.Literal(value)));
};
var Nullable = (schema, options) => {
  return Type.Union([schema, Type.Null()], {
    ..."title" in schema ? { title: schema.title } : {},
    ..."description" in schema ? { description: schema.description } : {},
    ..."default" in schema ? { default: schema.default } : {},
    ..."deprecated" in schema ? { deprecated: schema.deprecated } : {},
    ...options
  });
};
function isSchemaNullable(option) {
  return isSchemaUnion(option) && option.anyOf.some((o) => o[Kind] === "Null");
}
function getRelations(options) {
  const helper = {
    belongsTo(service, additionalOptions) {
      return {
        service,
        keyHere: "",
        keyThere: "id",
        asArray: false,
        nullable: false,
        ...additionalOptions ?? {}
      };
    },
    belongsToTypeUnsafe(service, additionalOptions) {
      return {
        service,
        keyHere: "",
        keyThere: "id",
        asArray: false,
        nullable: false,
        ...additionalOptions ?? {}
      };
    },
    hasMany(service, foreignKey, additionalOptions) {
      return {
        service,
        keyHere: "id",
        keyThere: foreignKey,
        asArray: true,
        nullable: false,
        ...additionalOptions ?? {}
      };
    },
    hasManyTypeUnsafe(service, foreignKey, additionalOptions) {
      return {
        service,
        keyHere: "id",
        keyThere: foreignKey,
        asArray: true,
        nullable: false,
        ...additionalOptions ?? {}
      };
    }
  };
  const virtualHelper = {
    virtual(service, asArray, getPopulateData, excludeForHasChange) {
      return {
        service,
        asArray,
        virtual: true,
        excludeForHasChange,
        getPopulateData
      };
    }
  };
  const relations = options.relations(helper);
  const virtual = options.virtual(virtualHelper);
  return { relations, virtual };
}
function defineRelations(properties, options) {
  const { relations: relationMap, virtual: virtualMap } = options;
  for (const key in relationMap) {
    const relation = relationMap[key];
    relation.nameAs ||= key;
    if (!relation.keyHere) {
      relation.keyHere = `${key}Id`;
    }
  }
  for (const key in virtualMap) {
    const relation = virtualMap[key];
    relation.nameAs ||= key;
  }
  const eachNonArrayRelation = Object.keys(relationMap).map((key) => relationMap[key]).filter((relation) => !relation.asArray);
  const relationIdProperties = {
    id: onlyNotCreate(Type.Readonly(Type.Integer())),
    uuid: frontendOnly(onlyNotCreate(Type.String()))
  };
  for (const relation of eachNonArrayRelation) {
    const backendIdKey = `${relation.nameAs}Id`;
    const frontendUuidKey = `${relation.nameAs}Uuid`;
    const isNullable = relation.nullable;
    relationIdProperties[backendIdKey] = isNullable ? Type.Optional(Nullable(Type.Integer())) : Type.Integer();
    relationIdProperties[frontendUuidKey] = onlyNotCreate(
      frontendOnly(
        isNullable ? Type.Optional(Nullable(Type.String({ virtual: true }))) : Type.String({ virtual: true })
      )
    );
  }
  return Type.Object(
    { ...relationIdProperties, ...properties },
    { additionalProperties: false }
  );
}
var schemaRegister = /* @__PURE__ */ new Map();
function defineSchema(service, properties, options) {
  const { relations, virtual } = getRelations({
    relations: options?.relations ?? (() => ({})),
    virtual: options?.virtual ?? (() => ({}))
  });
  const attached = {
    service,
    query: options?.query ?? {},
    relations,
    virtual
  };
  if (Array.isArray(properties)) {
    const schema = attachToSchema(
      Type.Union(
        properties.map(
          (value) => attachToSchema(
            defineRelations(value, {
              relations,
              virtual
            }),
            attached
          )
        )
      ),
      attached
    );
    schemaRegister.set(service, schema);
    return schema;
  } else {
    const schema = attachToSchema(
      defineRelations(properties, {
        relations,
        virtual
      }),
      attached
    );
    schemaRegister.set(service, schema);
    return schema;
  }
}

export { HexColor, LiteralUnion, Nullable, attachToSchema, defineRelations, defineSchema, getRelations, isSchemaNullable, schemaRegister };
