import _minBy from "lodash/minBy.js";
import useFeatureFlagStore from "./feathers/services/feature-flags/feature-flags.pinia";
import type { FeatureFlagName } from "@artesa/shared";

export const useFeatureFlags = defineStore("feature-flags-custom", () => {
  const companyId = useCompanyId();
  const featureFlagStore = useFeatureFlagStore().createScope();

  const { data: featureFlags, haveLoaded } = featureFlagStore.useFind({
    params: computed(() =>
      companyId.value
        ? {
            query: {
              $or: [{ companyId: companyId.value }, { companyId: null }],
              $limit: -1,
              enabledAt: { $ne: null },
            },
          }
        : null,
    ),
  });

  /**
   * returns `undefined` if the feature flags have not loaded yet
   */
  function isEnabled(name: FeatureFlagName) {
    return computed<boolean | undefined>(() => {
      if (!haveLoaded.value) {
        return undefined;
      }
      const allEnabledWithName = featureFlags.value.filter(flag => flag.name === name);
      const earliest = _minBy(allEnabledWithName, "enabledAt");
      return !!earliest && earliest.enabledAt! <= new Date();
    });
  }

  function useEnabledFeatureFlags() {
    return computed(() => {
      const now = new Date();
      return featureFlags.value.filter(flag => !flag.enabledAt || flag.enabledAt <= now);
    });
  }

  return {
    isEnabled,
    useEnabledFeatureFlags,
  };
});
