import parseProps from "vue-router-parse-props";
import type { ViewName } from "@artesa/shared";
import { viewNameEnum } from "@artesa/shared";
import type { RouteMeta } from "vue-router";

function idOrNewToNumberOrNull(value: string) {
  return value === "new" ? null : Number(value);
}

const propsDetailedAssignment = parseProps({
  assignmentId: idOrNewToNumberOrNull,
});

function detailedAssignment(path: string, name: ViewName, meta?: RouteMeta) {
  return {
    path,
    name,
    component: () => import("./assignments/DialogDetailedAssignment.vue"),
    props: propsDetailedAssignment,
    meta: {
      name: "Auftragsakte",
      permission: viewNameEnum.ViewAssignment,
      transition: null,
      ...meta,
    } satisfies RouteMeta,
  };
}

export default (): RouteRecordRaw[] => [
  {
    path: "office",
    redirect: () => {
      return { name: viewNameEnum.ViewPlanningBoard };
    },
  },
  // MARK: planning board
  {
    path: "office/board",
    name: viewNameEnum.ViewPlanningBoard,
    component: () => import("./board/ViewPlanningBoard.vue"),
    meta: {
      name: "Plantafel",
      icon: "fa-solid:chalkboard-teacher",
      permission: true,
    },
    children: [
      detailedAssignment(":assignmentId", viewNameEnum["ViewPlanningBoard.ViewAssignment"]),
    ],
  },
  // MARK: scheduler
  {
    path: "office/scheduler",
    name: viewNameEnum.ViewDepartmentScheduler,
    component: () => import("./gantt/ViewGantt.vue"),
    meta: {
      name: "Arbeitsbereichstafel",
      icon: "fa-regular:calendar-alt",
      permission: true,
    },
    children: [
      detailedAssignment(":assignmentId", viewNameEnum["ViewDepartmentScheduler.ViewAssignment"]),
    ],
  },
  // MARK: assignments
  {
    path: "office/assignment/create",
    name: viewNameEnum.ViewAssignmentCreate,
    component: () => import("./_views/ViewCreateAssignment.vue"),
    meta: {
      name: "Auftrag anlegen",
      icon: "fa-solid:plus-circle",
      permission: true,
    },
    props: parseProps({
      "query.initialLexOfficeObjectId": {
        type: Number,
        propKey: "initialLexOfficeObjectId",
      },
      "query.initialLexOfficeObjectType": {
        type: String,
        propKey: "initialLexOfficeObjectType",
      },
    }),
  },
  {
    path: "office/assignments",
    name: viewNameEnum.ViewAssignments,
    component: () => import("./assignments/ViewAssignments.vue"),
    meta: {
      name: "Aufträge",
      icon: "fa-solid:th-list",
      permission: true,
    },
    alias: "office/search",
    children: [detailedAssignment(":assignmentId", viewNameEnum["ViewAssignments.ViewAssignment"])],
  },
  // MARK: assignment events
  {
    path: "office/assignment-events",
    name: viewNameEnum.ViewAssignmentEvents,
    component: () => import("./_views/ViewAssignmentEvents.vue"),
    meta: {
      name: "Arbeitsschritte",
      icon: "fa-check-square",
      permission: true,
    },
    children: [
      detailedAssignment(":assignmentId", viewNameEnum["ViewAssignmentEvents.ViewAssignment"]),
    ],
  },
  // MARK: projects
  {
    path: "office/projects",
    name: viewNameEnum.ViewProjects,
    component: () => import("./projects/ViewProjects.vue"),
    meta: {
      name: "Projekte",
      icon: "fa-folder-open",
      permission: true,
    },
    children: [
      {
        path: ":projectId",
        name: viewNameEnum["ViewProjects.ViewProject"],
        component: () => import("./projects/DialogDetailedProject.vue"),
        props: parseProps({
          projectId: idOrNewToNumberOrNull,
        }),
        meta: {
          name: "Projektakte",
          permission: viewNameEnum.ViewProject,
        },
        children: [
          detailedAssignment(
            "assignment/:assignmentId",
            viewNameEnum["ViewProjects.ViewProject.ViewAssignment"],
          ),
        ],
      },
      // detailedAssignment(":assignmentId", viewNameEnum["ViewProjects.ViewProject.ViewAssignment"]),
    ],
  },
  // MARK: chats
  {
    path: "office/chats",
    name: viewNameEnum.ViewChatRooms,
    component: () => import("./_views/ChatRoomsView.vue"),
    meta: {
      name: "Chats",
      icon: "fa-regular:comments",
      permission: true,
    },
  },
  // MARK: customers
  {
    path: "office/customers",
    name: viewNameEnum.ViewCustomers,
    component: () => import("./customers/ViewCustomers.vue"),
    meta: {
      name: "Kunden",
      icon: "fa-regular:address-card",
      permission: true,
    },
    children: [
      {
        path: ":customerId",
        name: viewNameEnum["ViewCustomers.ViewCustomer"],
        component: () => import("./customers/DialogDetailedCustomer.vue"),
        props: parseProps({
          customerId: idOrNewToNumberOrNull,
        }),
        meta: {
          name: "Kundenakte",
          permission: viewNameEnum.ViewCustomer,
        },
        children: [
          detailedAssignment(
            "assignment/:assignmentId",
            viewNameEnum["ViewCustomers.ViewCustomer.ViewAssignment"],
          ),
        ],
      },
    ],
  },
  {
    path: "office/customers/csv",
    name: viewNameEnum["ViewCustomer.CSV"],
    component: () => import("./customers/CSVCustomerImport.vue"),
    meta: {
      name: "CSV",
    },
  },
  // MARK: custom forms
  {
    path: `office/forms`,
    name: viewNameEnum.ViewListForms,
    component: () => import("@/components/custom-forms/ViewListForms.vue"),
    meta: {
      name: "Formulare",
      icon: "fa-solid:pen-alt",
      permission: true,
    },
    children: [
      {
        name: viewNameEnum["ViewListForms.Form"],
        path: "form/:formId",
        component: () => import("@/components/custom-forms/DialogCustomForm.vue"),
        props: parseProps({
          formId: idOrNewToNumberOrNull,
        }),
      },
      detailedAssignment("assignment/:assignmentId", viewNameEnum["ViewListForms.ViewAssignment"]),
    ],
  },
  // MARK: LexOffice
  {
    path: "office/lexoffice/order-confirmations",
    name: viewNameEnum["LexOffice.OrderConfirmations"],
    component: () => import("./lexoffice/LexOfficeOrderConfirmations.vue"),
    meta: {
      name: "Lexware Office Auftragsbestätigungen",
      icon: "fa-solid:file-invoice",
      permission: true,
    },
    children: [
      detailedAssignment(
        "assignment/:assignmentId",
        viewNameEnum["LexOffice.OrderConfirmations.ViewAssignment"],
      ),
    ],
  },
  {
    path: "office/lexoffice/quotations",
    name: viewNameEnum["LexOffice.Quotations"],
    component: () => import("./lexoffice/LexOfficeQuotations.vue"),
    meta: {
      name: "Lexware Office Angebote",
      icon: "fa-solid:file-invoice",
      permission: true,
    },
    children: [
      detailedAssignment(
        "assignment/:assignmentId",
        viewNameEnum["LexOffice.Quotations.ViewAssignment"],
      ),
    ],
  },
];
