import type { LexOfficeIntegrationBase, LexOfficeIntegrationsExtraServices } from "@artesa/shared";
import { LEX_OFFICE_INTEGRATION_SERVICE_METHODS, LexOfficeIntegration } from "@artesa/shared";
import type { Service } from "@feathersjs/feathers";
import { defineFeathersPiniaStore } from "../../base-store";
import { makeTransformDatesHook } from "../../hooks/transformDates.hook";

declare module "../../feathers.client" {
  interface Services {
    "lex-office-integrations": Service<DateToString<LexOfficeIntegrationBase>> & LexOfficeIntegrationsExtraServices;
  }
}

export const useLexOfficeIntegrationStore = defineFeathersPiniaStore({
  schema: LexOfficeIntegration,
  servicePath: "lex-office-integrations",
  methods: LEX_OFFICE_INTEGRATION_SERVICE_METHODS,
  setupHook({ service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });
  },
});

export default useLexOfficeIntegrationStore;