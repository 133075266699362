import { NotAuthenticated } from "@feathersjs/errors";
import { defineHooks, paramsForServer } from "feathers-utils";
import { sentry } from "./hooks/sentry.hook";
// import { debug } from "./hooks/debug.hook";

export default defineHooks({
  around: {
    all: [sentry()],
  },
  before: {
    all: [
      paramsForServer("$populateParams", "$sentry-trace", "$baggage", "$span"),
      // checkPerformance(),
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },

  after: {
    all: [
      // checkPerformance(),
      // debug(),
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },

  error: {
    all: [
      context => {
        if (context.path === "authentication" || !(context.error instanceof NotAuthenticated)) {
          return;
        }

        const useAuthStore = context.app.get("useAuthStore");

        if (!useAuthStore) {
          return;
        }

        const authStore = useAuthStore();

        if (authStore?.isJwtExpired()) {
          authStore.logout();
          return;
        }
      },
    ],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});
