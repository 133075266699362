import { useCompanySettingStore } from "./company-settings.pinia";
import type { CompanySettingBase, CompanySettingFrontend } from "@artesa/shared";

export const useCompanySettingValue = <
  K extends keyof CompanySettingBase,
  Default extends CompanySettingFrontend[K] | null = null,
  Value = NonNullable<CompanySettingFrontend[K]>,
>(
  name: K,
  options?: {
    defaultValue?: Default;
    companyId?: MaybeRefOrGetter<number>;
  },
): [ComputedRef<Value | Default>, (val: CompanySettingFrontend[K]) => void] => {
  const companyId = options?.companyId !== undefined ? options?.companyId : useCompanyIdOrNull();

  const store = useCompanySettingStore().createScope();

  const { data: companySetting } = store.useGet({
    id: companyId,
    lazy: true,
  });

  function set(val: CompanySettingFrontend[K]) {
    if (!companySetting.value) {
      return;
    }

    companySetting.value[name] = val;
  }

  const value = computed<Value | Default>(
    () => (companySetting.value?.[name] ?? options?.defaultValue ?? null) as Value | Default,
  );

  return [value, set] as const;
};
