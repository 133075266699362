<template>
  <slot />
  <ArtesaLightbox v-if="generalStore.lightboxIndex !== null"></ArtesaLightbox>
  <PdfViewer v-if="pdfStore.isOpen"></PdfViewer>
</template>

<script setup lang="ts">
const generalStore = useGeneralStore();

const pdfStore = usePdfStore();

const router = useRouter();

const pushNotificationStore = usePushNotificationStore();

pushNotificationStore.showPermissionBox();

watch(router.currentRoute, (route, oldRoute) => {
  // TODO: Is there a way to detect if router.push or router.replace was called?

  const newPath = new URL(route.fullPath, window.location.origin).pathname;
  const oldPath = oldRoute ? new URL(oldRoute.fullPath, window.location.origin).pathname : null;

  if (!oldPath || newPath === oldPath) {
    return;
  }

  const newParentPath = newPath.split("/").slice(0, -1).join("/");
  const oldParentPath = oldPath.split("/").slice(0, -1).join("/");

  if (newParentPath === oldParentPath) {
    return;
  }

  if (newPath.startsWith(oldPath)) {
    generalStore.predecessorRoutes.push(oldRoute);
    return;
  }

  const routes = generalStore.predecessorRoutes;

  for (let i = 0, n = routes.length; i < n; i++) {
    const lastPath = new URL(routes[i].fullPath, window.location.origin).pathname;

    if (lastPath === newPath) {
      generalStore.predecessorRoutes = routes.slice(0, i);
      break;
    }

    if (!newPath.startsWith(lastPath)) {
      generalStore.predecessorRoutes = routes.slice(0, i);
      break;
    }
  }
});

useImpersonationStore();

const currentInstance = getCurrentInstance();

const { setAppContext } = useGeoTrackingStore();

if (currentInstance) {
  setAppContext(currentInstance.appContext);
} else {
  Sentry.captureException(
    new Error("No current instance to set app context for geo tracking store"),
  );
}
</script>

<script lang="ts">
import { usePushNotificationStore } from "./domains/notifications/web-push/web-push.store";
import { useGeneralStore } from "./store/general.store";
import { useImpersonationStore } from "./store/impersonation.store";
import { usePdfStore } from "./store/pdf.store";
import { useGeoTrackingStore } from "./store/geotracking.store";
import { Sentry } from "./main.plugins/main.sentry";

const ArtesaLightbox = defineAsyncComponent(() => import("./components/ArtesaLightbox.vue"));
const PdfViewer = defineAsyncComponent(() => import("./components/PdfViewer.vue"));
</script>
