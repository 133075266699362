import { viewNameEnum } from "@artesa/shared";
import { PATH_TIME_SHEET, timeTracking } from "./utils";

export default (): RouteRecordRaw[] => [
  // TODO: remove this route
  {
    path: "check/time-sheets",
    redirect: () => ({ name: viewNameEnum["ViewTimeSheetDays"] }),
  },
  {
    path: timeTracking,
    alias: "check",
    name: "MainTimeTracking",
    redirect: () => ({ name: viewNameEnum["ViewTimeSheetDays"] }),
  },
  // TODO: remove this route
  {
    path: `${timeTracking}/time-sheets`,
    redirect: () => ({ name: viewNameEnum["ViewTimeSheetDays"] }),
  },
  {
    path: PATH_TIME_SHEET,
    name: viewNameEnum["ViewTimeSheetDays"],
    component: () => import("./time-sheet/ViewTimeSheet.vue"),
    meta: {
      name: "Stunden&shy;zettel",
      icon: "fa-solid:business-time",
      permission: true,
    },
  },
  {
    path: `${timeTracking}/periods-overview`,
    name: viewNameEnum["ViewPeriodsOverview"],
    component: () => import("./periods-overview/ViewPeriodsOverview.vue"),
    meta: {
      name: "Übersicht Zeiterfassung",
      icon: "mdi:chart-timeline",
      permission: true,
    },
  },
  {
    path: `${timeTracking}/export`,
    name: viewNameEnum["ViewAssignmentWorkPeriodsExport"],
    component: () => import("./ViewWorkPeriodsExport.vue"),
    meta: {
      name: "Arbeitszeit&shy;auswertung",
      icon: "fa-solid:file-export",
      permission: true,
    },
  },
  // TODO: remove this route
  {
    path: "check/office/export",
    redirect: () => ({ name: viewNameEnum["ViewAssignmentWorkPeriodsExport"] }),
  },
];
