import * as Sentry from "@sentry/vue";
import type { Router } from "vue-router";

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: integrations => integrations.filter(integration => integration.name !== "Vue"),
    // 1.0 == 100%
    tracesSampleRate: 1, // for less 0.1
    replaysSessionSampleRate: 1, // for less 0.01
    replaysOnErrorSampleRate: 1, // for less 0.1
    environment: import.meta.env.VITE_DEPLOYMENT_ENV,
    parentSpanIsAlwaysRootSpan: false,
    transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
    transportOptions: {
      maxQueueSize: 200,
    } as any /* https://docs.sentry.io/platforms/javascript/best-practices/offline-caching/ */
  });
}

export { Sentry };

export default function (router: Router) {
  return {
    install: (app: App) => {
      if (import.meta.env.VITE_SENTRY_DSN) {
        Sentry.addIntegration(
          Sentry.vueIntegration({
            app,
          }),
        );
        Sentry.addIntegration(Sentry.browserTracingIntegration({ router }));
        Sentry.addIntegration(Sentry.replayIntegration());
        Sentry.addIntegration(Sentry.browserProfilingIntegration());
      }

      return app;
    },
  };
}
