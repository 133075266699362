import type { Service } from "@feathersjs/feathers";
import type { LexOfficeOrderConfirmationBase, LexOfficeOrderConfirmationsExtraServices } from "@artesa/shared";
import { LEX_OFFICE_ORDER_CONFIRMATION_METHODS, LexOfficeOrderConfirmation } from "@artesa/shared";
import { defineFeathersPiniaStore } from "../../base-store";
import { makeTransformDatesHook } from "../../hooks/transformDates.hook";

declare module "../../feathers.client" {
  interface Services {
    "lex-office-order-confirmations": Service<DateToString<LexOfficeOrderConfirmationBase>> & LexOfficeOrderConfirmationsExtraServices;
  }
}

export const useLexOfficeOrderConfirmationStore = defineFeathersPiniaStore({
  schema: LexOfficeOrderConfirmation,
  servicePath: "lex-office-order-confirmations",
  methods: LEX_OFFICE_ORDER_CONFIRMATION_METHODS,
  setupHook({ service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });
  },
});

export default useLexOfficeOrderConfirmationStore;