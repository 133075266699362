import type { Service } from "@feathersjs/feathers";
import type { LexOfficeQuotationBase, LexOfficeQuotationsExtraServices } from "@artesa/shared";
import { LEX_OFFICE_QUOTATION_METHODS, LexOfficeQuotation } from "@artesa/shared";
import { defineFeathersPiniaStore } from "../../base-store";
import { makeTransformDatesHook } from "../../hooks/transformDates.hook";

declare module "../../feathers.client" {
  interface Services {
    "lex-office-quotations": Service<DateToString<LexOfficeQuotationBase>> & LexOfficeQuotationsExtraServices;
  }
}

export const useLexOfficeQuotationStore = defineFeathersPiniaStore({
  schema: LexOfficeQuotation,
  servicePath: "lex-office-quotations",
  methods: LEX_OFFICE_QUOTATION_METHODS,
  setupHook({ service, transformDates }) {
    const transformDatesHook = makeTransformDatesHook(transformDates);
    service.hooks({
      after: {
        find: [transformDatesHook],
        get: [transformDatesHook],
        create: [transformDatesHook],
        update: [transformDatesHook],
        patch: [transformDatesHook],
        remove: [transformDatesHook],
      },
    });
  },
});

export default useLexOfficeQuotationStore;