/**
 * Get the companyId from the currently logged-in user
 */
export const useCompanyId = createSharedComposable(() => {
  const loggedInUser = useUser();

  return computed<number>(() => {
    if (!loggedInUser.value) {
      throw new Error("User is not logged in");
    }
    return loggedInUser.value?.companyId as number;
  });
});
