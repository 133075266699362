import { Kind, ReadonlyKind, OptionalKind, Type } from '@sinclair/typebox';
import { deepEqual } from 'fast-equals';

// src/util/guards.ts
function isSchemaUnion(schema) {
  return Kind in schema ? schema[Kind] === "Union" : "anyOf" in schema;
}
function isSchemaIntersect(schema) {
  return Kind in schema ? schema[Kind] === "Intersect" : "allOf" in schema;
}
function isSchemaObject(schema) {
  return Kind in schema ? schema[Kind] === "Object" : schema.type === "object";
}
function isSchemaReadonly(options) {
  if (ReadonlyKind in options) {
    return options[ReadonlyKind] === "Readonly";
  }
  if (isSchemaIntersect(options)) {
    return options.allOf.every((value) => value[ReadonlyKind] === "Readonly");
  }
  if (isSchemaUnion(options)) {
    return options.anyOf.some((value) => value[ReadonlyKind] === "Readonly");
  }
  return false;
}
function isSchemaVirtual(option) {
  if ("virtual" in option) {
    return option.virtual === true;
  }
  if (isSchemaIntersect(option)) {
    return option.allOf.every((value) => value.virtual === true);
  }
  if (isSchemaUnion(option)) {
    return option.anyOf.some((value) => value.virtual === true);
  }
  return false;
}
function isSchemaOptional(option) {
  return option[OptionalKind] === "Optional";
}
var combineObjectUnion = (schema) => {
  if (!isSchemaUnion(schema)) {
    return schema;
  }
  const result = {};
  for (const obj of schema.anyOf) {
    if (!isSchemaObject(obj)) {
      continue;
    }
    for (const key in obj.properties) {
      const value = obj.properties[key];
      if (!result[key]) {
        result[key] = { merged: value, schemas: [value] };
        continue;
      }
      const currentResult = result[key];
      if (!currentResult.schemas.some((v) => deepEqual(v, value))) {
        currentResult.merged = {
          ...currentResult.merged,
          ...value
        };
        currentResult.schemas.push(value);
      }
    }
  }
  const properties = {};
  for (const key in result) {
    const { merged, schemas } = result[key];
    if (schemas.length === 1) {
      properties[key] = schemas[0];
    } else {
      const schema2 = Type.Union(schemas);
      for (const key2 in merged) {
        if (key2 === "type" || key2 === "properties") {
          continue;
        }
        schema2[key2] = merged[key2];
      }
      properties[key] = schema2;
    }
  }
  return Type.Object(properties);
};
var StringEnum = (allowedValues, options) => {
  const toRecord = {};
  allowedValues.forEach((value) => toRecord[value] = value);
  return Type.Enum(toRecord, options);
};
var NumberEnum = (allowedValues, options) => {
  return Type.Union(
    allowedValues.map((value) => Type.Literal(value)),
    options
  );
};

// src/extended-types/frontend-only.ts
var FrontendOnlyKind = Symbol.for("Artesa/FrontendOnly");
function frontendOnly(schema) {
  const modifiedSchema = schema;
  modifiedSchema[FrontendOnlyKind] = true;
  return modifiedSchema;
}
function isSchemaFrontendOnly(option) {
  if (FrontendOnlyKind in option) {
    return !!option[FrontendOnlyKind];
  }
  if (isSchemaIntersect(option)) {
    return option.allOf.every((value) => FrontendOnlyKind in value && !!value[FrontendOnlyKind]);
  }
  if (isSchemaUnion(option)) {
    return option.anyOf.some((value) => FrontendOnlyKind in value && !!value[FrontendOnlyKind]);
  }
  return false;
}

// src/extended-types/not-in-create.ts
var OnlyNotCreateKind = Symbol.for("Artesa/OnlyNotCreate");
function onlyNotCreate(schema) {
  const modifiedSchema = schema;
  modifiedSchema[OnlyNotCreateKind] = true;
  return modifiedSchema;
}
function isSchemaOnlyNotCreate(option) {
  if (OnlyNotCreateKind in option) {
    return !!option[OnlyNotCreateKind];
  }
  if (isSchemaIntersect(option)) {
    return option.allOf.every((value) => OnlyNotCreateKind in value && !!value[OnlyNotCreateKind]);
  }
  if (isSchemaUnion(option)) {
    return option.anyOf.some((value) => OnlyNotCreateKind in value && !!value[OnlyNotCreateKind]);
  }
  return false;
}

export { FrontendOnlyKind, NumberEnum, OnlyNotCreateKind, StringEnum, combineObjectUnion, frontendOnly, isSchemaFrontendOnly, isSchemaIntersect, isSchemaObject, isSchemaOnlyNotCreate, isSchemaOptional, isSchemaReadonly, isSchemaUnion, isSchemaVirtual, onlyNotCreate };
