/**
 * Get the companyId from the currently logged-in user
 */
export const useCompanyIdOrNull = createSharedComposable(() => {
  const loggedInUser = useUser();

  return computed<number | null>(() => {
    return loggedInUser.value?.companyId ?? null;
  });
});
