import { defineStore } from "pinia";
import { useCompanySettingValue } from "./feathers/services/company-settings/useCompanySettingValue";
import useUserSettingStore from "./feathers/services/user-settings/user-settings.pinia";
import type { UserFrontend } from "@artesa/shared";
import { Sentry } from "@/main.plugins/main.sentry";
import type { AppContext } from "vue";
import { ElMessage } from "element-plus";
import { useFeatureFlags } from "./feature-flags.store";

export type GeoPositionResult =
  | {
      type: "success";
      location: GeolocationPosition;
    }
  | {
      type: "error";
      error: GeolocationPositionError;
    }
  | {
      type: "unknown_error";
      error: unknown;
    };

export const useGeoTrackingStore = defineStore("geo-tracking", () => {
  const userSettingsStore = useUserSettingStore().createScope();
  const user = shallowRef<UserFrontend | null>();

  const { t } = useI18n();

  let appContext: AppContext | null = null;

  const { data: userSetting } = userSettingsStore.useGet({
    id: computed(() => user.value?.id),
    lazy: true,
  });

  const isFFEnabled = useFeatureFlags().isEnabled("GeoTracking");

  const [companySetting] = useCompanySettingValue("isTimeTrackingWithGeolocation");

  const isEnabled = computed<boolean>(() =>
    userSetting.value?.isTimeTrackingWithGeolocation === true
      ? true
      : userSetting.value?.isTimeTrackingWithGeolocation === false
        ? false
        : (companySetting.value ?? false),
  );

  async function getGeoPosition(): Promise<GeoPositionResult | null> {
    if (!isEnabled.value) {
      return null;
    }

    if (!isFFEnabled.value) {
      return null;
    }

    try {
      const position = await new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          position => {
            resolve(position);
          },
          error => {
            reject(error);
          },
          {
            // 1 minute in ms
            maximumAge: 1 * 60 * 1000,
            // 15 seconds in ms
            timeout: 15 * 1000,
            // High accuracy
            enableHighAccuracy: true,
          },
        );
      });

      return {
        type: "success",
        location: position,
      };
    } catch (error) {
      if (error instanceof GeolocationPositionError) {
        if (appContext) {
          if (error.code === error.PERMISSION_DENIED) {
            ElMessage(
              {
                message: t("geoTracking.errors.PERMISSION_DENIED"),
                type: "error",
                showClose: true,
              },
              appContext,
            );
          } else if (error.code === error.POSITION_UNAVAILABLE) {
            ElMessage(
              {
                message: t("geoTracking.errors.POSITION_UNAVAILABLE"),
                type: "error",
                showClose: true,
              },
              appContext,
            );
          } else if (error.code === error.TIMEOUT) {
            ElMessage(
              {
                message: t("geoTracking.errors.TIMEOUT"),
                type: "error",
                showClose: true,
              },
              appContext,
            );
          }
        }

        return {
          type: "error",
          error,
        };
      }
      Sentry.captureException(error);
      if (appContext) {
        ElMessage(
          {
            message: t("geoTracking.errors.UNKNOWN"),
            type: "error",
            showClose: true,
          },
          appContext,
        );
      }
      return {
        type: "unknown_error",
        error,
      };
    }
  }

  return {
    isEnabled,
    getGeoPosition,
    // Idk why useTimeTrackingStore is using a shallow user reference, but I will just mirror this behavior here
    syncUser: (newUser: UserFrontend | null) => {
      user.value = newUser;
    },
    setAppContext(context: AppContext) {
      appContext = context;
    },
  };
});
